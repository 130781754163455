<template>
  <div>
    <main>
      <h1>特定商取引法に基づく表記</h1>
      <section>
        <h2>販売業者</h2>
        <p>株式会社Modelor</p>
      </section>
      <section>
        <h2>本社</h2>
        <p>
          〒603-8832<br>
          京都市北区大宮南田尻町3-1<br>
          電話番号: 050-3628-7290
        </p>
      </section>
      <section>
        <h2>お問い合わせ先</h2>
        <p>support@smashoku.com</p>
      </section>
      <section>
        <h2>販売価格</h2>
        <p>料金プランによります。料金プランについては、サイトトップをご覧ください。</p>
      </section>
      <section>
        <h2>送料</h2>
        <p>サービスの特性上、送料は発生いたしません。</p>
      </section>
      <section>
        <h2>その他お客様の負担する費用</h2>
        <p>インターネット利用のために必要となる通信料及び接続料（金額は、お客様が契約した各事業者にお問い合わせください。）</p>
      </section>
      <section>
        <h2>お支払方法</h2>
        <p>支払い方法は、クレジットカード決済のみとなります。</p>
      </section>
      <section>
        <h2>代金の支払い時期</h2>
        <p>初回購入時、1 ヶ月後から月毎請求</p>
      </section>
      <section>
        <h2>申込み有効期限</h2>
        <p>なし</p>
      </section>
      <section>
        <h2>商品引渡し又はサービス提供の時期</h2>
        <p>登録手続の完了後直ちに提供します。</p>
      </section>
      <section>
        <h2>キャンセル</h2>
        <p>解約につきましては、マイページにて行ってください。(https://smashoku.com/label/mypage/companies/me/invoices)</p>
        <p>自動的に次回より請求を停止致します。 尚、日割り分等での返金は承っておりませんのでご了承ください。</p>
        <p>なお、ご利用開始後、14日 以内に解約した場合、費用は発生いたしません。ただし、同一人物にて繰り返しご利用される場合は、その限りではありません。</p>
      </section>
      <section>
        <h2>動作環境</h2>
        <p>Windows OS 7以上,Mac OS X以上、Google Chrome の最新バージョンにてご利用ください。</p>
      </section>
      <section>※ 上記以外の事項に関しましては、お取引の際に請求があれば遅延なく提示いたします。</section>
    </main>
    <footer-component></footer-component>
  </div>
</template>

<script>
  import FooterComponent from "../components/FooterComponent.vue";
  import {dispatchInitialized} from "@/libs/cypress";

  export default {
    components: {
      FooterComponent
    },
    mounted() {
      dispatchInitialized();
    }
  }
</script>

<style lang="scss" scoped>
  @import 'objects/pages/_page-terms.scss';
</style>
