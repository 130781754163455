<template>
  <div>
    <main>
      <h1>特定商取引法に基づく表記</h1>
      <section>
        <h2>販売業者</h2>
        <p>株式会社Modelor</p>
      </section>
      <section>
        <h2>本社</h2>
        <p>
          〒603-8832<br>
          京都市北区大宮南田尻町3-1<br>
          電話番号: 050-3628-7290
        </p>
      </section>
      <section>
        <h2>お問い合わせ先</h2>
        <p>support@smashoku.com</p>
      </section>
      <section>
        <h2>販売価格</h2>
        <p>料金プランによります。料金プランについては、サイトトップをご覧ください。</p>
      </section>
      <section>
        <h2>送料</h2>
        <p>サービスの特性上、送料は発生いたしません。</p>
      </section>
      <section>
        <h2>その他お客様の負担する費用</h2>
        <p>インターネット利用のために必要となる通信料及び接続料（金額は、お客様が契約した各事業者にお問い合わせください。）</p>
      </section>
      <section>
        <h2>お支払方法</h2>
        <p>支払い方法は、銀行振込となります。振込先は、ご依頼後にメールにて通知いたします。</p>
      </section>
      <section>
        <h2>代金の支払い時期</h2>
        <p>当月末締翌月末払い</p>
      </section>
      <section>
        <h2>申込み有効期限</h2>
        <p>なし</p>
      </section>
      <section>
        <h2>商品引渡し又はサービス提供の時期</h2>
        <p>料金プランと利用者のご準備状況によります。具体的な日程については、メールにて通知いたします。</p>
      </section>
      <section>
        <h2>キャンセル</h2>
        <p>作業着手前までキャンセルすることができます。作業着手については、メールにて通知いたします。</p>
        <p>作業着手後のキャンセルについては、お引き受けすることができませんので、予めご了承ください。</p>
      </section>
      <section>※ 上記以外の事項に関しましては、お取引の際に請求があれば遅延なく提示いたします。</section>
    </main>
    <FooterComponentAgency></FooterComponentAgency>
  </div>
</template>

<script>
import FooterComponentAgency from './FooterComponentAgency.vue';
import {dispatchInitialized} from "@/libs/cypress";
export default {
  components: {
    FooterComponentAgency
  },
  mounted() {
    dispatchInitialized();
  }
}
</script>

<style lang="scss" scoped>
  @import 'objects/pages/_page-terms.scss';
</style>
